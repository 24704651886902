import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Resume from './Pages/Resume';
import HomePage from './Pages/HomePage';
import './App.css';
import MainNav from './Components/MainNav';
import AboutPage from './Pages/AboutPage';
import StudyPage from './Pages/StudyPage';
import BlogPage from './Pages/BlogPage';

function App() {
  return (

    <BrowserRouter>
    <MainNav/>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/resume" element={<Resume />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/study" element={<StudyPage />} />
        <Route path="/blog" element={<BlogPage />} />
      </Routes>
    </BrowserRouter>

  );
}

export default App;
