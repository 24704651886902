import { Fragment } from "react"



export interface BlogPostProps {
    title: string
    author: string
    date: string
    content: string
}

const BlogPost: React.FC<BlogPostProps> = ({ title, author, date, content }) => {

    return (
        <>
            <h2>{title}</h2>
            <p>By {author} on {date}</p>
            <p dangerouslySetInnerHTML={{ __html: content }}></p >
        </>
    );
};


export default BlogPost;