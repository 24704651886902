import Container from 'react-bootstrap/Container';
import ButtonLink from '../Components/ButtonLink';

function AboutPage() {
  return (
    <Container>
      <h1>About</h1>
      <img src="me.png"></img>      
      <p>They told me I needed a web site so I said okay.</p>
    </Container>
  );
}

export default AboutPage;
