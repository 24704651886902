// todo - list objective with name and image 
import { Card } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';

export type Objective = {
    id: number,
    title: string,
}& {
    imageUrl?: string;
}


function ObjectiveCard(objective: Objective) {
    return (
        <Card>
            {objective.imageUrl !==null ?(
                <Card.Img src={objective.imageUrl}></Card.Img>                
            ) : null }
            <Card.Body>
                <Card.Text>
                {objective.title}
                </Card.Text>
            </Card.Body>

        </Card>
    )

}

type Props = {
    objectives: Objective[];
}


function StudyObjectives(props: Props) {
  return (
<Container>
<h2>Study objectives</h2>
{props.objectives.map(o => (
    <ObjectiveCard id={o.id} title={o.title} imageUrl={o.imageUrl}/>
))}
</Container>
  );
}

export default StudyObjectives; 