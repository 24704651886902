import Container from 'react-bootstrap/Container';

import BlogPost from '../Components/BlogPost';


// first - just display one post 

// then display latest post from post metadata 

// foreach blog post 
// put a truncated view of the top 3 




function BlogPage() {
  const post = {
    title: "Hello evrinyun",
    author: "Chiyo-Dad",
    date: "04-21-2023",
    content: "<p>How are you? Fine sank you.</p> <img src='./azumanga.png'/>"

  }
  return (
    <Container>
      <BlogPost title={post.title} author={post.author} date={post.date} content={post.content} />

    </Container >
  );
}

export default BlogPage;
