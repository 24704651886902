import Container from 'react-bootstrap/Container';
import ButtonLink from '../Components/ButtonLink';
import TextLink from '../Components/TextLink';

function HomePage() {
  return (
    <Container id="centered-container">
      <img src='header.jpg'></img>
    </Container>
  );
}

export default HomePage;
