import Container from 'react-bootstrap/Container';
import StudyObjectives from '../Components/StudyObjectives';
import { Objective } from '../Components/StudyObjectives';

// todo:
// want our study page to have current work area/texts being studied named objectives 
// each objective will have sections or chapters (sections)
// read annotate exercise tasks - read section, annotate section (upload picture/OCR? of notes ), exercises (bonus points for tests for correctness of exercise)
//  Current, past, upcoming objectives 
// click on objective to get progress 
// home page is just progress for current objectives
// crud for objectives (upload picture, later add isbn support with auto icon)
// new objective - how many sections, which tasks per section (exercises have subsections)

// start with objectives 
// auth/firebase 
// add crud for objectives 

function StudyPage() {
let objectives: Objective[] = [{
  "id" : 1,
  "title" :"butt"
}, {
  "id" : 2,
  "title" : "poop",
  "imageUrl" : "http://redonline.cdnds.net/main/thumbs/25788/stack_of_books.jpg"
}]

  return (
    <Container>
            <h1>Study page </h1>
            <StudyObjectives objectives={objectives}></StudyObjectives>
        
    </Container>
  );
}

export default StudyPage;